import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import './assets/styles/reset.css'
// 使用Vue.use来安装插件
Vue.use(VueRouter);
 
// 定义路由
// 假设有个Home.vue组件和About.vue组件
const Home = () => import('./components/Home.vue');
const About = () => import('./components/About.vue');
const Solution = () => import('./components/solution.vue');
 
// 创建路由实例
const router = new VueRouter({
  mode: 'history', // 使用HTML5 History模式
  routes: [
    { path: '/', component: Home },
    { path: '/about', component: About },
    { path: '/solution', component: Solution },
  ]
});
Vue.config.productionTip = false
// 创建和挂载根实例
new Vue({
  router, // 使用路由
  render: h => h(App),
}).$mount('#app');